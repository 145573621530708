import * as React from 'react';
import styled from 'styled-components';
import {StaticImage} from "gatsby-plugin-image";
import { mq } from "../common/styles";
import Quote from '../components/Quote';
import PageContent from '../components/PageContent';
import Layout from '../components/Layout';
import Salutation from '../components/Salutation';
import CallToAction from '../components/CallToAction';

const StyledPage = styled.div`
  display: flex;
  flex-direction: column-reverse;
  ${mq.laptop} {
    flex-direction: row;
  }
  .message {
    ${mq.laptop} {
     width: 50vw;
    }
  }
  .imgWrapper {
    margin-bottom: 24px;
    ${mq.laptop} {
      margin-bottom: 0;
      margin-left: 24px;
      width: 50vw;
    }
  }
  .bottom-center-image {
    margin-top: 32px;
    display: flex;
    justify-content: center;
  }
  .center-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export function About() {
  return (
    <Layout subPageTitle='About'>
          <PageContent>
              <StyledPage>
                  <div className='message'>
                      <Salutation>Humble Beginnings</Salutation>
                      <p>
                          Sarah grew up in a big, country house right across the way from the Hune Covered Bridge, surrounded by the Wayne National Forest. Her home was one of 13 homes in Washington County, Ohio, in the National Register of Historic Homes. During her childhood years, Sarah spent many hours each day in the woods outside her house. She loved to explore, and could be found doing just about anything there is to do outside, including camping, building forts and treehouses, and dancing across the hay bales to the barn. Many days you could find her floating down the Little Muskingum River.
                      </p>
                      <p>
                        The country house days were days of self discovery and adventure, and Sarah often refers to it as “the house that built her.” In 2019, she and her husband took their wedding reception photos at the bridge, just before it was officially closed to all driving traffic, signaling the end of one chapter and the beginning of another.
                      </p>
                      <p>
                          Fast forward to now, Sarah still seeks comfort in her roots and lives in a family heirloom country house on the Ohio River with her family where they enjoy their riverfront view. Together, they enjoy relaxing on the deck, swimming off the dock, kayaking, canoeing, paddle boarding, and fishing from their bass boat. Their home is the perfect place for making memories and doubles as a great location for intimate, outdoor events with endless possibilities such as a boat ride on the Sistersville Ferry or a Sip-and-Snip party on the deck.
                      </p>
                      <p>
                        With her Communications degree and Wedding and Event Planning certification from The University of Akron, Sarah can add the perfect touches to any special occasion, and she’s been doing just that over the last decade as a freelancer.
                      </p>
                      <p>
                        With her eye for detail and heart for adventure, in 2021, Ivory & Rust, LLC was born.
                      </p>
                      <Quote
                          quote='Go confidently in the direction of your dreams! Live the life you’ve imagined.'
                          author='Henry David Thoreau'
                      />
                  </div>
                  <div className='imgWrapper'>
                    <StaticImage layout='fluid' maxWidth={1600} src='../images/portraits/horz-hune.jpeg' alt='Sarah Portrait Field'/>
                    <br />
                    <br />
                    <div className='hide-mobile'>
                        <StaticImage layout='fluid' src={'../images/portraits/group-hune-bridge.jpg'} maxWidth={1600} alt='Hune Bridge Bride Tribe'/>
                    </div>
                  </div>
              </StyledPage>
              <br />
              <StyledPage>
                  <div className='center-content'>
                      <CallToAction
                          to='/gallery'
                          actionText='Check out My Gallery'
                          primerText='Memories are for always'
                      />
                  </div>
                  <div className='hide-laptop'>
                      <div className='center-content'>
                          <br />
                          <StaticImage src={'../images/portraits/group-hune-bridge.jpg'} layout='fluid' style={{width: '100%'}} maxWidth={1600} alt='Hune Bridge Bride Tribe'/>
                      </div>
                  </div>
              </StyledPage>
          </PageContent>
    </Layout>
  );
}

export default About;
