import * as React from 'react';
import styled from 'styled-components';
import { mq, fonts, colors } from '../common/styles';

const StyledQuote = styled.blockquote`
  margin: 16px 0px 16px 8px;
  padding-left: 16px;
  border-left: 2px solid ${colors.rust};
  ${mq.laptop} {
    margin: 32px 0px 32px 8px;
  } 
  .quote {
    font-size: 16px;
    ${mq.laptop} {
      letter-spacing: 0.5px;
      font-size: 18px;
    }
    font-family: ${fonts.nothingYouCouldDo};
  }
  .author {
    font-size: 12px;
    font-weight: 300;
  }
`;

function Quote(props) {
  const { quote, author, className } = props;
  return (
    <StyledQuote className={className}>
      <div className='quote'>"{quote}"</div>
      {author && <div className='author'>- {author}</div>}
    </StyledQuote>
  );
}

export default Quote;

